// import seenImage from './img/seen.png';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-calendar/dist/Calendar.css';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import React, { useState,useEffect,useRef } from 'react';
import Calendar from 'react-calendar';
import { FaAngleDown,FaAngleUp, FaInfoCircle, FaCalendarAlt, FaUtensils , FaRegEdit, FaCheckCircle, FaHome, FaTimesCircle, FaArrowRight } from 'react-icons/fa';
import { BiCalendarEdit, BiMenu, BiSpa } from 'react-icons/bi';
import {MdOutlineOpenInNew} from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';
import styled1 from 'styled-components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
function App(){
  const currentUrl = window.location.href;
    const segments = currentUrl.split('/');
  const [date, setDate] = useState(new Date());
  const [maxGuest,setMaxGuest] = useState("1");
  const [time, setTime] = useState(`00:00`);
  // const [startTime, setStartTime] = useState(null);
  // const [endTime, setEndTime] = useState(null);
  // const [intervalTime , setIntervalTime] = useState("1");
  const urlParams = new URLSearchParams(window.location.search);
  const venue=segments[3];
  const bookingIdGet = urlParams.get('booking_id');
  // setIsBookingId(urlParams.get('booking_id'));
  const [addonData,setAddonData] = useState([]);
  const [addonCategory,setAddonCategory] = useState([]);
  const[startDate , setStartDate] = useState("");
  const[endDate , setEndDate] = useState("");
  const[isMainShow , setIsMainShow] = useState(false);
  const[isConfirmShow , setIsConfirmShow] = useState(false);
  const[bookingId , setBookingId] = useState("");
  const[isFailedShow , setIsFailedShow] = useState(false);
  const[isProcessLoader, setIsProcessLoader] = useState(true);
  const[companyName,setCompanyName] = useState("");
  const[isCancelShow,setIsCancel] = useState(false);
  // if(bookingIdGet != null){
  //   setIsCancel(true);
  //   setIsConfirmShow(false);
  //   setIsFailedShow(false);
    // setIsMainShow(false);
    // setIsProcessLoader(false);
  // }
  const formattedDate = date.toLocaleDateString('en-US', {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const showHideCalendar = () => {
    setIsCalendarVisible(!isCalendarVisible);
  };
 
  const [isCurrentButtonVisible ,setIsCurrentButtonVisible] = useState(true);
  const [isFutureVisible ,setIsFutureVisible] = useState(false);
  const showCurrentTime = (event) => {
    setIsFutureVisible(false);
    setIsCurrentButtonVisible(true);
  }
  // get the every day of date range
  const getDatesInRange = (start, end) => {
    const dates = [];
    const current = new Date(start);
    const last = new Date(end);

    while (current <= last) {
      dates.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }

    return dates;
  };
  const [dateRange , setDateRange] = useState([]);
  const showFutureTime = (event) => {
    if(startDate !== "" && endDate !== ""){
      setDateRange(getDatesInRange(startDate, endDate));
      setIsCurrentButtonVisible(false);
      setIsFutureVisible(true);
    }else{
      // console.log("notify goes here");
      toast.info('No special hour available!', {
        position: toast.POSITION.TOP_RIGHT,
        // Other options if needed
        autoClose:1000
      });
    }
  }
  //get the if same date
  const isSameDate = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };
  // Submit the Form Of Guest
  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };
  const [formData, setFormData] = useState({
    first_name: '',
    last_name:'',
    email: '',
    phone:phoneNumber,
    // birthday:'',
    reservation_notes:'',
    event_date:'',
    time:'',
    terms_condition:'',
    news_offer_venue:"",
    room_number:''
  });
  // alert(time);  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const [addonFormId,setAddonFormId] = useState("");
  const[addonArray,setAddonaArray] = useState([]);
  const[addonDataArray,setAddonDataArray] = useState([]);

  // const [addonId ,setAddonId] = useState("");
  // const [addonName ,setAddonName] = useState("");
  // const [addonRate ,setAddonRate] = useState("");
  const submitAsGuest = async (event) =>{
    event.preventDefault();
    // Perform form submission logic or data processing here
    // console.log(addonDataArray);
    // const urlParams = new URLSearchParams(window.location.search);
    const updatedFormData = { ...formData, 
      time: time,
      phone: phoneNumber,
      guest:guestCapacity,
      venue:urlParams.get('venue'),
      addon_id:addonDataArray,
      // addon_rate:addonRate,
      event_date:date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
    };

    setIsProcessLoader(true);
    try { 
      const response = await fetch('https://btlone-admin.btltech.xyz/api/Booking/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',  
        },
        body: JSON.stringify(updatedFormData), // Replace with your request body
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
          if(data['status'] === true){
            toast.success(data['message'],{
              position: toast.POSITION.TOP_RIGHT,
              autoClose:1500
            });
            setBookingId(data['booking_id']);
            setTimeout(() => {
              setIsMainShow(false);
              setIsConfirmShow(true);
              // setIsAddonShow(true);
              setIsProcessLoader(false);
            }, 2000);
          }else if(data['status'] === false){
            toast.error(data['message'],{
              position: toast.POSITION.TOP_RIGHT,
              autoClose:1500
            });
            setTimeout(() => {
              setIsFailedShow(true);
              setIsMainShow(false);
              setIsProcessLoader(false);
            }, 2000);
          }
      }else{
        // Request failed
        console.log('Request failed');
      }
    } catch (error) {
      console.log('Error:', error);
    }

  };
  // get the records on page load
  const [timeIntervalArray, setTimeIntervalArray] = useState([]);
  const [availableSlot,setAvailableSlot] = useState([]);
  const [isDateTimeVisible,setIsDateTimeVisible] = useState(true);
  const[isAddonShow, setIsAddonShow] = useState(false);
  const [isSummaryVisible,setIsSummaryVisible] = useState(false);
  const[isTimeIntervalVisible ,setIsTimeIntervalVisible ] = useState(true);
  const[isDateIntervalVisible ,setIsDateIntervalVisible ] = useState(false);
  const url = "https://btlone-admin.btltech.xyz/assets/img/clients/";
  const [backgroundImage ,setBackgroundImage] =useState("");
  const [descText ,setDescText] =useState("");
  const [logo,setLogo] = useState("");
  const[backgroundColorDynamic,setBackgroundColor ] = useState("");
  const showLoginPanelSpecial = (event)=>{
    const clickedTimeValue = event.target.id;
    const clickedDateValue = event.target.value;
    setDate(new Date(clickedDateValue));
    setTime(clickedTimeValue);
    setIsDateTimeVisible(false);
    setIsSummaryVisible(true);
    setIsSelectionDivVisible(false);
    setIsSelectedValueVisible(true);
  };
  const showLoginPanel = (event)=>{
    const clickedValue = event.target.value;
    setTime(clickedValue);
    setIsDateTimeVisible(false);
    if(addonCategory.length != 0){
      setIsAddonShow(true);
    }else{
      setIsSummaryVisible(true);
    }
    
    
    setIsSelectionDivVisible(false);
    setIsSelectedValueVisible(true);
  };
  const [activeItem, setActiveItem] = useState(0);
  
  const [bookStatus,setBookStatus] = useState('0');
  const [venueId ,setVenueId] = useState(null);
  const [bookingDate,setBookingDate] =useState("");
  const [mobileBg,setMobileBg] =useState("");
  const [country_id,setCountry_id] = useState("");
  const [customField,setCustomField] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isAddonCategoryVisible, setIsAddonCategoryVisible] = useState(false);
  const[categoryName ,setCategoryName] = useState("");
  const[categoryKey,setCategoryKey] = useState(0);
  const[venue_type,setvenue_type] = useState("");
  const [guestCapacity, setGuestCapacity] = useState(2);
  useEffect(() => {
    const checkViewportWidth = () => {
      setIsMobile(window.innerWidth < 768);
      setIsAddonCategoryVisible(window.innerWidth > 768);
    };
    // alert(segments[3])
    // this value used for url
    const modal = document.getElementById('mainModal');
    modal.style.display = 'block';
    const formattedDateforurl = date.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    const fetchData = async () => {
      getDates(date);
      try{
        const urlParams = new URLSearchParams(window.location.search);
              //to get the data from url
              const params = {
                venue:urlParams.get('venue'),
                date:formattedDateforurl,
                booking_id:bookingIdGet,
              };
              // alert(params['date'])
        // const response = await fetch(`https://btlone-admin.btltech.xyz/api/OpeningHours/index`);
        const url = new URL('https://btlone-admin.btltech.xyz/api/OpeningHours/index');
        url.search = new URLSearchParams(params).toString();
        const response = await fetch(url);
        if (response.ok){
          const jsonData = await response.json();
          // console.log(jsonData)
          if(jsonData['status'] === true){
            setTimeout(() => {
              setIsMainShow(true);
              // setIsAddonShow(true);
              setIsProcessLoader(false);
            }, 2000);
            if(jsonData['data']['end_date'] !== null){
              setEndDate(jsonData['data']['end_date']);
            }
            setAddonData(jsonData['addon']);
            setAddonCategory(jsonData['addon_category']);
            if(jsonData['addon_category'].length !== 0){
              setActiveItem(jsonData['addon_category'][0]['id']);
              setCategoryName(jsonData['addon_category'][0]['name']);
            }
            if(jsonData['data']['start_date'] !== null){
              setStartDate(jsonData['data']['start_date']);
            }
            setCustomField(jsonData['settingList']);
            setBookStatus(jsonData['book_status']);
            setBookingDate(new Date(jsonData['date']));
            setMaxGuest(jsonData['data']['max_guests_interval']);
            setTime(jsonData['data']['time_start']);
            // if(jsonData['available'] != null){
              setAvailableSlot(jsonData['available']);
            // }
            // alert(jsonData['day_book_status']);
            if(jsonData['day_book_status'] === true){
              setIsTimeIntervalVisible(false);
              setIsDateIntervalVisible(true);
            }else{
              setIsTimeIntervalVisible(true);
              setIsDateIntervalVisible(false);
            }
            // setStartTime(jsonData['data']['time_start']);
            // setEndTime(jsonData['data']['time_end']);
            // setIntervalTime(jsonData['data']['interval_time']);
            generateTimeIntervals(jsonData['data']['time_start'], jsonData['data']['time_end'], jsonData['data']['interval_time']);
            // setIsTimeIntervalVisible(true);
            // setIsDateIntervalVisible(false);
            setVenueId(jsonData['setting']['id']);
            setBackgroundColor(jsonData['setting']['background_color']);
            setCompanyName(jsonData['setting']['company_name']);
            setLogo(jsonData['setting']['logo']);
            setBackgroundImage(jsonData['setting']['background_image']);
            setMobileBg(jsonData['setting']['bg_img_mob']);
            setDescText(jsonData['setting']['description_text']);
            setCountry_id(jsonData['setting']['country_id']);
            setvenue_type(jsonData['setting']['venue_type']);
            if(jsonData['setting']['venue_type'] === "spa"){
              setGuestCapacity(1);
            }
          }else if(jsonData['status'] === false){
            setTimeout(() => {
              setIsMainShow(true);
              setIsProcessLoader(false);
            }, 2000);
            setCustomField(jsonData['settingList']);
            setBookingDate(new Date(jsonData['date']));
            setAddonData(jsonData['addon']);
            setAddonCategory(jsonData['addon_category']);
            setBookStatus(jsonData['book_status']);
            setCompanyName(jsonData['setting']['company_name']);
            setVenueId(jsonData['setting']['id']);
            setBackgroundColor(jsonData['setting']['background_color']);
            setLogo(jsonData['setting']['logo']);
            setMobileBg(jsonData['setting']['bg_img_mob']);
            setBackgroundImage(jsonData['setting']['background_image']);
            setCountry_id(jsonData['setting']['country_id']);
            setDescText(jsonData['setting']['description_text']);
            setvenue_type(jsonData['setting']['venue_type']);
            if(jsonData['setting']['venue_type'] === "spa"){
              setGuestCapacity(1);
            }
            setTimeIntervalArray([]);
            setIsDateIntervalVisible(true);
            setIsTimeIntervalVisible(false);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    checkViewportWidth();
    window.addEventListener('resize', checkViewportWidth);
    return () => {
      window.removeEventListener('resize', checkViewportWidth);
    };
  }, []);

  // set date when time slot is not available
  const setAnotherCalendarDate = async (event) =>{
    const datenew = event.target.value;
    const dateForUrl = new Date(datenew).toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    try {
      const urlParams = new URLSearchParams(window.location.search);
              //to get the data from url
              const params = {
                venue:urlParams.get('venue'),
                date:dateForUrl,
              };
              const url = new URL('https://btlone-admin.btltech.xyz/api/OpeningHours/index');
      url.search = new URLSearchParams(params).toString();
      const response = await fetch(url);
      // const response = await fetch(`https://btlone-admin.btltech.xyz/api/OpeningHours/index?date=${dateForUrl}`);
      if (response.ok){
        const jsonData = await response.json();
        if(jsonData['status'] === true){
          setMaxGuest(jsonData['data']['max_guests_interval']);
          setTime(jsonData['data']['time_start']);
          // setStartTime(jsonData['data']['time_start']);
          // setEndTime(jsonData['data']['time_end']);
          // setIntervalTime(jsonData['data']['interval_time']);
          if(jsonData['data']['end_date'] !== null){
            setEndDate(jsonData['data']['end_date']);
          }
          if(jsonData['data']['start_date'] !== null){
            setStartDate(jsonData['data']['start_date']);
          }
          setAvailableSlot(jsonData['available']);
          setIsCalendarVisible(false);
          generateTimeIntervals(jsonData['data']['time_start'], jsonData['data']['time_end'], jsonData['data']['interval_time']);
          setIsTimeIntervalVisible(true);
          setIsDateIntervalVisible(false);
        }else{
          setTimeIntervalArray([]);
          setIsDateIntervalVisible(true);
          setIsTimeIntervalVisible(false);
          toast.warning('Sorry no booking available please select another date!', {
            position: toast.POSITION.TOP_RIGHT,
            // Other options if needed
            autoClose:1500
          });
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setDate(new Date(datenew));
  }
  // set date on change of calendar
  const setCalendarDate = async (newDate) => {
    // setDate(newDate);
    const dateForUrl = newDate.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    try {
      const urlParams = new URLSearchParams(window.location.search);
              //to get the data from url
              const params = {
                venue:urlParams.get('venue'),
                date:dateForUrl,
              };
        const url = new URL('https://btlone-admin.btltech.xyz/api/OpeningHours/index');
      url.search = new URLSearchParams(params).toString();
      const response = await fetch(url);
      // const response = await fetch(`https://btlone-admin.btltech.xyz/api/OpeningHours/index?date=${dateForUrl}`);
      if (response.ok){
        const jsonData = await response.json();
        if(jsonData['status'] === true){
          setMaxGuest(jsonData['data']['max_guests_interval']);
          setTime(jsonData['data']['time_start']);
          // setStartTime(jsonData['data']['time_start']);
          // setEndTime(jsonData['data']['time_end']);
          // setIntervalTime(jsonData['data']['interval_time']);
          setAvailableSlot(jsonData['available']);
          if(jsonData['data']['end_date'] !== null){
            setEndDate(jsonData['data']['end_date']);
          }
          if(jsonData['data']['start_date'] !== null){
            setStartDate(jsonData['data']['start_date']);
          }
          setIsCalendarVisible(false);
          generateTimeIntervals(jsonData['data']['time_start'], jsonData['data']['time_end'], jsonData['data']['interval_time']);
          setIsTimeIntervalVisible(true);
          setIsDateIntervalVisible(false);
        }else{
          setIsCalendarVisible(false);
          setTimeIntervalArray([]);
          setIsDateIntervalVisible(true);
          setIsTimeIntervalVisible(false);
          toast.warning('Sorry no booking available please select another date!', {
            position: toast.POSITION.TOP_RIGHT,
            // Other options if needed
            autoClose:1500
          });
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setDate(newDate);

  }
  // get the time Slot of every time interval
  const generateTimeIntervals = (startTime, endTime, intervalMinutes) => {
    const intervals = [];
    const start = new Date(`01/06/2023 ${startTime}`);
    const end = new Date(`01/06/2023 ${endTime}`);
    const interval = intervalMinutes * 60000; // Convert minutes to milliseconds
    let current = start;
    while (current <= end) {
      intervals.push(current.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }));
      current = new Date(current.getTime() + interval);
    }
    // console.log(intervals);
    setTimeIntervalArray(intervals);
  };
  const guestArr = Array.from({ length: maxGuest }, (_, index) => index + 1);
  // console.log(guestArr);
  const [isSelectionDivVisible,setIsSelectionDivVisible] = useState(true);
  const [isSelectedValueVisible,setIsSelectedValueVisible] = useState(false);
  
  const guestCount = (event)=>{
    const value = event.target.value;
    if(value !== "" || value !== 0){
      setGuestCapacity(value);
      setIsSelectionDivVisible(false);
      setIsSelectedValueVisible(true);
    }
  };
  const backToSelection = () =>{
    setIsSelectedValueVisible(false);
    setIsSelectionDivVisible(true);
    setIsSummaryVisible(false);
    setIsAddonShow(false);
    setIsDateTimeVisible(true);
    setIsFutureVisible(false);
    setIsCurrentButtonVisible(true);
  }
  // const currentDate = new Date(date);
  const [nextDates,setNextDate] = useState([]);
      function getDates (datePass){
        const dateForUrl = datePass.toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
          // const urlParams = new URLSearchParams(window.location.search);
              //to get the data from url
              const params = {
                venue:urlParams.get('venue'),
                date:dateForUrl,
              };
              // https://btlone-admin.btltech.xyz/
              const url = new URL('https://btlone-admin.btltech.xyz/api/OpeningHours/getAvailableDates');
                url.search = new URLSearchParams(params).toString();
                  fetch(url)
                    .then(response => response.json())
                    .then(data => {
                          setNextDate(data['data']);
                          setBackgroundColor(data['setting']['background_color']);
                          setLogo(data['setting']['logo']);
                          setBackgroundImage(data['setting']['background_image']);
                          setDescText(data['setting']['description_text']);
                    })
                    .catch(error => {
                      // Handle any errors that occurred during the request
                      console.error(error);
                    });
      }
      // console.log(nextDates);
  const dayTitles = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const currentDateNew = new Date();
  const minDate = currentDateNew;
  // const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
  const[isReservation_notesVisble , setIsReservation_notesVisble] = useState(false);
  const showReservationNotes = (event)=>{
    setIsReservation_notesVisble(!isReservation_notesVisble);
  }
  const gotoHome =(event)=>{
    setIsProcessLoader(true);
    setTimeout(() => {
      setIsConfirmShow(false);
      setIsProcessLoader(false);
      setIsMainShow(true);
      setIsSelectionDivVisible(true);
      setIsSelectedValueVisible(false);
      window.location.reload();
    }, 2000);
  }
  // const closeModal =(event)=>{
  //   const modal = document.getElementById('mainModal');
  //   modal.style.display = 'none';
  // }
  const showModal =(event)=>{
    const modal = document.getElementById('mainModal');
    modal.style.display = 'block';
  }
  const CalendarContainer = styled.div`
    .react-calendar__tile--active abbr{
      background-color:${backgroundColorDynamic};

    }
    button.react-calendar__tile abbr:hover {
      background-color:${backgroundColorDynamic};
    }
    button.react-calendar__tile abbr {
      border-color:${backgroundColorDynamic} !important;
    }
    .react-calendar__tile:disabled abbr {
      border: none !important;
      background-color: #f0f0f0;
      border-radius: 0;
    }
    button.react-calendar__navigation__arrow{
      color:${backgroundColorDynamic} !important;
    }
    button.react-calendar__navigation__arrow:hover{
      color:#fff !important;
      background-color:${backgroundColorDynamic} !important;
    }
    .react-calendar__navigation button:disabled {
      background-color: #f0f0f0;
      color:#ccc !important;
      cursor: not-allowed;
    }
    .react-calendar__navigation button:disabled:hover {
      background-color: #f0f0f0 !important;
      color:#ccc;
    }
    .react-calendar__month-view__days__day--weekend{
      color:#000 !important;
    }
    .react-calendar__month-view__days__day--weekend:disabled{
      color:#ccc !important;
    }
    .active-category{
      background-color:${backgroundColorDynamic} !important;
    }
  `;
  // const SectionContainer = styled1.div`
  //   .backgroundImage{
  //     background-image: url(${url+backgroundImage});
  //   }
  //   @media (max-width: 768px) {
  //     .backgroundImage{
  //       background-image: url(${url+mobileBg});
  //     }
  //   }
  //   #mainModal{
  //     display:block;
  //   }
  // `;
  // const currentDate = new Date(date);
  // alert(currentDate)
  // const tileDisabled = ({ date, view }) => {
  //   if (view === 'month') {
  //     // Disable dates from the next month
  //     const currentYear = currentDate.getFullYear();
  //     const currentMonth = currentDate.getMonth();
  //     const selectedYear = date.getFullYear();
  //     const selectedMonth = date.getMonth();
  //     return (
  //       // date.getFullYear() > currentYear ||
  //       // (date.getFullYear() === currentYear && date.getMonth() > currentMonth)
  //       selectedYear !== currentYear || selectedMonth !== currentMonth
  //     );
  //   }
  //   return false;
  // };
  const [otherBoxVisible,setOtherBoxVisible] = useState(false);
  const showOtherBbox = ()=>{
    setOtherBoxVisible(!otherBoxVisible);
  }
  const [radioValue, setRadioValue] = useState('');
  const [textareaOther, setTextareaOther] = useState('');
  function handleRadioChange(event) {
    setRadioValue(event.target.value);
  }
  function handleTextareaChange(event) {
    setTextareaOther(event.target.value);
  }
  const handleCancel = async (event) => {
    event.preventDefault();
    const cancelData = {
      cancelOption:radioValue,
      cancelReason:textareaOther,
      venue_id:venueId,
      booking_id:bookingIdGet
    };
    try {
      //https://btlone-admin.btltech.xyz/
      const response = await fetch('https://btlone-admin.btltech.xyz/api/Booking/cancel',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',  
        },
        body: JSON.stringify(cancelData), // Replace with your request body
      });
      if (response.ok){
        const data = await response.json();
        console.log(data);
          if(data['status'] === true){
            window.location.reload();
          }else if(data['status'] === false){
            
          }
      }else{
        // Request failed
        console.log('Request failed');
      }
    } catch (error) {
      console.log('Error:', error);
    }
    // setRadioValue('');
    // setTextareaOther('');
  }
  const goToSite =()=>{
    const currentUrl = window.location.href.split('?')[0];
    // alert(currentUrl);
    const newUrl = `${currentUrl}`;
    window.location.href = newUrl+'?venue='+urlParams.get('venue');
  }
  
  
  // const contentDivRef = useRef(null);
  // const[isScroll,setIsScroll] = useState(false);
  
  // const handleScroll = () => {
  //   const container = contentDivRef.current;
  //   setIsScroll(true);
  //   if(isScroll === true){
  //     if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
  //       setIsScroll(false);
  //         if(addonCategory.length - 1  > categoryKey){
  //           const itemId = addonCategory[categoryKey+1]['id'];
  //           handleItemClick(itemId,categoryKey+1);
  //           // alert(categoryKey);
  //         }else{
  //         }
  //     }
  //   }
    
  // };
  const showCategoryModal =()=>{
    const modal = document.getElementById('addonArrayModal');
    modal.style.display = "block";
  }
  const handleItemClick = async (itemId,key) => {
    // alert(itemId);
    const modal = document.getElementById('addonArrayModal');
    modal.style.display = "none";
    setIsProcessLoader(true);
    setActiveItem(itemId);
    setCategoryKey(key);
    const selectedObject = addonCategory.find(item => item['id'] === itemId);
    setCategoryName(selectedObject.name);
    setIsAddonCategoryVisible(false);
    try {
              const params = {
                venue:urlParams.get('venue'),
                category:itemId,
              };
      const url = new URL('https://btlone-admin.btltech.xyz/api/OpeningHours/getAddons');
      url.search = new URLSearchParams(params).toString();
      const response = await fetch(url);
      if (response.ok){
        setAddonData([]);
        setIsProcessLoader(false);
        const jsonData = await response.json();
        if(jsonData['status'] === true){
          setAddonData(jsonData['data']);
          setTimeout(() => {
            if(addonDataArray.length !== 0){
              addonDataArray.map((value,index)=>{
                const checkboxes = document.getElementById("checkbox"+value['id']);
                if (checkboxes){
                  checkboxes.checked = true;
                }
              });
            }
          }, 50);
        }else{
          setAddonData([]);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // document.addEventListener('touchmove', handleScroll);
    // // document.addEventListener('scroll', handleScroll);

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      // document.removeEventListener('touchmove', handleScroll);
      // document.removeEventListener('scroll', handleScroll);
    };
  };
  
  const handleCheckboxChange = (checkboxId,name,rate)=>{
    let keys = Object.keys(addonArray);
    if(keys.includes(checkboxId) && addonArray[checkboxId] === true){
      const updatedItems = addonDataArray.filter((item) => item.id !== checkboxId);
      setAddonDataArray(updatedItems);
        setAddonaArray((prevCheckboxes) => ({
        ...prevCheckboxes,
        [checkboxId]: !prevCheckboxes[checkboxId],
      }));
    }else{
      const newValue = {
        id:checkboxId,
        rate:rate,
        name:name
      }
      setAddonDataArray(prevValues => [...prevValues, newValue]);
      setAddonaArray((prevCheckboxes) => ({
        ...prevCheckboxes,
        [checkboxId]: !prevCheckboxes[checkboxId],
      }));
    }   
  };
  // console.log(addonDataArray);
  const isMobileDivVisibility = ()=>{
    setIsAddonCategoryVisible(!isAddonCategoryVisible);
  }
  const skipAddon =() =>{
    setIsAddonShow(false);
    setIsSummaryVisible(true);
  }
  const deleteAddon = (itemId)=>{
    const updatedItems = addonDataArray.filter((item) => item.id !== itemId);
    setAddonDataArray(updatedItems);
    var checkbox = document.getElementById('checkbox'+itemId);
    if (checkbox){
      // Set the checked property to false to uncheck it
      checkbox.checked = false;
    }
    setAddonaArray((prevCheckboxes) => ({
      ...prevCheckboxes,
      [itemId]: !prevCheckboxes[itemId],
    }));
  }
  function ReadMore({ text, maxLength }) {
    const [isTruncated, setIsTruncated] = useState(true);
  
    const toggleTruncate = () => {
      setIsTruncated(!isTruncated);
    };
  
    return (
      <div className='readmore-position'>
      {text.length > maxLength ?
      <>
         {isTruncated ? (
          <p>{text.slice(0, maxLength)}...</p>
         ) : (
          <p>{text}</p>
         )
        }
        <span className='cursorPointer' onClick={toggleTruncate}>
          {isTruncated ? 
          ( <><FaAngleDown className='readmoreText'/></>) :(<> <FaAngleUp className='readmoreTextUp' /></>)
          }
        </span>
      </>
      :<p>{text}</p>}
        
      </div>
    );
  }
  const hideModal =(event) =>{
    const modal = document.getElementById('infoModal');
        modal.style.display = 'none';
  }
  const hideAddonModal =(event) =>{
    const modal = document.getElementById('addonArrayModal');
        modal.style.display = 'none';
  }
  const showAddonmodal = (event)=>{
    const modal = document.getElementById('infoModal');
        modal.style.display = 'block';
  };
  return (
    // style={{backgroundImage:`url(${url+backgroundImage})`}}
    // <SectionContainer>
    <main className='backgroundImage' style={{backgroundImage:`url(${isMobile?url+mobileBg:url+backgroundImage})`}} >
      <div className='text-center' onClick={showModal} style={{display:"none"}}>
        <button className='modal-open' style={{backgroundColor:backgroundColorDynamic,borderColor:backgroundColorDynamic}}>Book Table</button>
      </div>
      <ToastContainer />
      <div className="modal fade" id="mainModal" tabIndex="-1" role="dialog">
		  <div className="modal-dialog modal-dialog-centered" role="document">
		    <div className="modal-content">
		      <div className="modal-header" style={{backgroundColor:backgroundColorDynamic,padding:isMobile ?"0":""}} >
                  <span></span>
                  {isMobile && 
                    <img width="50px" height="50px" src={url+'logo/'+logo} alt="not found" style={{marginBottom:"10px"}} />
                  }
                  {isMobile === false && 
                      <img width="100px" height="100px" src={url+'logo/'+logo} alt="not found"  />
                  }
                  <span></span>
		        {/* <h5 className="modal-title" id="exampleModalLongTitle"><button type="button" onClick={handleAddSpecialToForm} className="btn btn-primary">Save</button></h5> */}
              {/* <button type="button" className="close btn" onClick={closeModal}>
                <span aria-hidden="true">×</span>
              </button> */}
		      </div>
		      <div className="modal-body">
            {isMainShow && bookingIdGet === null &&
              <div className="col-lg-12 col-12 col-md-12">
              <div className='seen_text_main'>
                <div className='text-white' style={{backgroundColor:backgroundColorDynamic}}>
                  <div className='d-flex justify-content-between' >
                    
                  </div>
                  {/* for restaurent description */}
                  {isAddonShow === false && 
                  isSummaryVisible === false &&
                    <div className='row'>
                      <div className='col-md-2 col-2 p-0'></div>
                      <div className='col-md-8'>
                      <p className='text-center' style={{fontSize:"13px",fontWeight:"500"}}>{descText}</p>
                      </div>
                      <div className='col-md-2 col-2 p-0'></div>
                    </div>
                  }
                  {/* end  restaurent description */}
                  {isSelectionDivVisible && 
                  <div className='row' style={{padding:"20px"}}>
                  <div className='col-12 col-sm-12 col-lg-6 cursorPointer' style={{position:"relative",height:"100%"}}>
                    <div className="top" onClick={showHideCalendar}>
                      <h3 id="monthYear"><FaCalendarAlt /> &nbsp;&nbsp;{formattedDate} <span style={{float:"right"}}><FaAngleDown /></span></h3>
                    </div>
                    {isCalendarVisible &&
                        <div className="calendar-bottom">
                            <CalendarContainer>
                              <Calendar 
                                onChange={setCalendarDate}
                                formatShortWeekday={(locale, date) => dayTitles[date.getDay()]}
                                value={date}
                                minDate={minDate}
                                // tileDisabled={tileDisabled}
                                // maxDate={maxDate}
                              />
                            </CalendarContainer>
                        </div>
                      }
                  </div>
                  <div className='col-12 col-sm-12 col-lg-6 d-flex'>
                    <div className='bottom_input'>
                    <div className='col-1 icon-media-spn'>
                      {venue_type === "spa" && 
                        <BiSpa /> 
                      }
                      {venue_type === "f&b" &&
                        <FaUtensils />
                      }
                    </div>
                    <div className='col-11'>
                      
                      <select className='select-guest-cap' value={guestCapacity} onChange={guestCount} style={{backgroundColor:backgroundColorDynamic}}>
                      {guestArr.length === 1 ?
                        <option value="">2 guests</option>
                      :
                        guestArr.map((noofguest,index) => (
                          index === 0 ?<option key={index} value={index+1}>{index+1} guest</option>:<option key={index} value={index+1}>{index+1}  guests</option>
                        ))
                      }
                        {/* {guestArr.map((noofguest,index) => (
                          index === 0 ?<option key={index} value={index+1}>{index+1} guest</option>:<option key={index} value={index+1}>{index+1}  guests</option>
                        ))} */}
                      </select>
                      
                    </div>
                    </div>
                  </div>
                  </div>
                  }
                  {isSelectedValueVisible &&
                    <div className="taking_main_div_text">
                          <div className='row' style={{padding: "0px 32px 15px 26px"}}>
                            <div className='col-sm-12 col-lg-12 selected-bar d-flex justify-content-between align-items-center' style={{borderColor:backgroundColorDynamic}}>
                            <span className="text_guests">
                              {date.toLocaleDateString('en-US', {
                                weekday: 'short',
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                              })}&nbsp;&nbsp;·&nbsp;&nbsp;{guestCapacity} {guestCapacity > 1 ?"guests":"guest"}&nbsp;&nbsp;·&nbsp;&nbsp;{time}</span>
                              {addonDataArray.length !== 0 ? 
                                  <span className='cursorPointer readmore-font'
                                    style={{color:'blue',textDecoration:'underline'}}
                                    onClick={showAddonmodal}
                                  >
                                      Addons
                                  </span>
                                :
                                ""
                              }
                              
                              <span className="cursorPointer head-icon" style={{borderColor:backgroundColorDynamic,color:backgroundColorDynamic}} onClick={backToSelection}> <BiCalendarEdit/></span>
                            </div>
                          </div>
                    </div>
                  }
                  {/* https://booktechlabs.com/wp-content/uploads/2023/05/Logo__2_-removebg-preview.png */}
                </div>
              <div className="calendar">
                <div className='bg-white'>
                {isDateTimeVisible &&
                  <div className='p-3 bg-white'>
                    {isTimeIntervalVisible &&
                      <>
                      <div className="dates_other">
                       <div className="p-2 bg-white seen_taking" style={{fontSize:"16px",fontWeight:"500",color:backgroundColorDynamic}}>Select a time slot</div>
                         {/* {timeIntervalArray.map((interval, index) => (
                           <button style={{backgroundColor:backgroundColorDynamic,borderColor:backgroundColorDynamic}} key={index} value={interval} onClick={showLoginPanel}>{interval}</button>
                         ))} */}
                         {Object.keys(availableSlot).length !== 0 ? 
                         Object.keys(availableSlot).map((interval , index) => (
                          <button style={{backgroundColor:backgroundColorDynamic,borderColor:backgroundColorDynamic}} key={index} value={interval} onClick={showLoginPanel}>{interval}</button>
                         ))
                         :
                         ""}
                       </div>
                       <div className="d-flex justify-content-between bg_dark_text text-white special-outer d-none">
                     <div className="safety_time">
                        Special hours availability
                     </div>
                     <div className="availability">
                           <span>{isCurrentButtonVisible && <FaAngleDown className='cursorPointer' onClick={showFutureTime}/>}
                                 {isFutureVisible && <FaAngleUp className='cursorPointer' onClick={showCurrentTime} />}
                           </span>
                     </div>
                  </div>
                  {isFutureVisible && 
                     <div className="button_div">
                     <div className="btn_text">
                     {dateRange.map((daydate, index) => (
                        <div className="dates" key={index} style={{ display: isSameDate(daydate, date) ? 'none' : 'block',}}>
                           <span>{daydate.toDateString()}</span>
                           <div className="dates_other">
                           {timeIntervalArray.map((interval, index) => (
                              <button onClick={showLoginPanelSpecial} value={daydate} id={interval} key={index}>{interval}</button>
                           ))}
                           </div>
                        </div>
                     ))}
                     </div>
                  </div>
                   }
                       </>
                    }
                    {isDateIntervalVisible && 
                     <>
                     <div className="row alert alert-danger">
                        <div className='col-1'>
                        <FaInfoCircle className='text-white' style={{fontSize:"25px",marginTop:"5px"}}/>
                        </div>
                        <div className='col-11'>
                        <span className='alert-text' ><b>For the selected {guestCapacity} guests, there is no availability on&nbsp; 
                        {date.toLocaleDateString('en-US', {
                                  day: 'numeric',
                                  weekday: 'long',
                                  month: 'long',
                                  year: 'numeric',
                                  
                                })}
                          </b>, please find below other available dates that might fulfil your request.</span>
                        </div>
                      </div>
                     <div className="dates_future">
                     {nextDates.length !== 0 ?
                        nextDates.map((nextdate, index) => (
                          <button style={{backgroundColor:backgroundColorDynamic,borderColor:backgroundColorDynamic}}  key={index} onClick={setAnotherCalendarDate} value={nextdate} >{
                            new Date(nextdate).toLocaleDateString('en-US',{
                            weekday: 'short',
                            month: 'short',
                            day: '2-digit'
                          })}</button>
                        )):""}
                     {/* nextDates */}
                       {/* <button>{nextdate.toLocaleDateString('en-US',{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      }) }</button> */}
                   </div>
                     </>
                    }
                    
                  {/* { timeIntervalArray.length !== 0 ?<></>:<></>} */}
                </div>
                }
                {isAddonShow &&
                  <>
                  {/* <CalendarContainer > */}
                    <div className='p-3 bg-white'>
                      {isMobile && 
                      <div className='row mobile-menu-addon' onClick={isMobileDivVisibility}
                        style={{
                          position:"relative",
                          top:"-5px",
                        }}
                      >
                        
                        <div className='d-flex cursorPointer active-category'
                         style={{
                          width:"50%",
                          marginLeft:"10px",
                          height: "35px",
                          marginBottom:"8px",
                          alignItems:"center",
                          backgroundColor:backgroundColorDynamic
                        }}
                        >
                          <BiMenu style={{fontSize:"20px"}}/> <span className='addonName' style={{marginLeft:"2px",marginTop:"-2px",padding:"3px"}} >{categoryName}</span>
                        </div>
                        
                      </div>
                      
                      }
                      <div className='row'>
                      {isMobile === false && 
                        <div className='col-lg-4 col-12'>
                                  <ul className="list-category">
                                    {addonCategory.length !== 0 ? 
                                        addonCategory.map((value,key)=>(
                                          <li key={key} className={activeItem === value['id'] ? 'active-category' : ''}
                                            style={{backgroundColor:`${activeItem === value['id']?backgroundColorDynamic:""}`}}
                                            onClick={() => handleItemClick(value['id'],key)}
                                          >{value['name']}</li>
                                        ))
                                      :
                                      ""
                                    }
                                  </ul>
                        </div>
                      }
                      {isMobile  && isAddonCategoryVisible && 
                        <div className='col-lg-4 col-12'>
                                  <ul className="list-category">
                                    {addonCategory.length !== 0 ? 
                                        addonCategory.map((value,key)=>(
                                          <li key={key} className={activeItem === value['id'] ? 'active-category' : ''}
                                            onClick={() => handleItemClick(value['id'],key)}
                                            style={{backgroundColor:`${activeItem === value['id']?backgroundColorDynamic:""}`}}
                                          >{value['name']}</li>
                                        ))
                                      :
                                      ""
                                    }
                                  </ul>
                        </div>
                      }
                        <div className='col-lg-8 col-12 addon-scroll'
                          // ref={contentDivRef}
                          // onScroll={handleScroll}
                          style={{
                            overflowY: 'scroll',
                            height: '275px' // Set the desired height for the div
                          }}
                        >
                           <button className='checkout-font cursorPointer btn btn-primary mb-3' onClick={skipAddon} style={{backgroundColor:backgroundColorDynamic,borderColor:backgroundColorDynamic}}>Proceed to Checkout</button>
                              {addonData.length !== 0 ?
                                addonData.map((value,key)=>(
                                  <div className='row' key={key}>
                                    <div className='col-lg-6 col-6'>
                                      <span className='addonName'>{value['name']}</span>
                                    </div>
                                    <div className='col-lg-3 col-3'>
                                      <span className='float-right readmore-font mt-1'>$ {value['base_rate']}</span>
                                    </div>
                                    <div className='col-lg-3 col-3'>
                                      <input type='checkbox' className='float-right mt-1'
                                        style={{marginRight:"10px"}}
                                        id={"checkbox"+value['id']}
                                        onChange={() => handleCheckboxChange(value['id'],value['name'],value['base_rate'])} />
                                    </div>
                                    <div className='col-lg-12 readmore-font'>
                                      <span>
                                        <ReadMore text={value['description']} maxLength={48} />
                                      </span>
                                    </div>
                                  </div>
                                ))
                                :""}
                        </div>
                        <div className='col-lg-12 mt-2' style={{position:"relative",top:"15px"}}>
                        {/* <button className='checkout-font float-right cursorPointer btn btn-primary' onClick={skipAddon} style={{backgroundColor:backgroundColorDynamic,borderColor:backgroundColorDynamic}}>Proceed to Checkout</button> */}
                        </div>
                      </div>
                    </div>
                    {/* </CalendarContainer> */}
                  </>
                }
                {isSummaryVisible && 
                <form onSubmit={submitAsGuest}>
                <div className='safety_during'>
                  {customField.length !== 0 ?
                  <div className="text-center information">
                         <h6>Your Information </h6>
                        <div className="row mt-3 m-0">
                        {customField['first_name'] === '1'?
                          <div className="col-lg-6 col-sm-6 col-12 p-10">
                             <div className="form-group">
                               <input type="text" name="first_name" id="first_name" value={formData.first_name} onChange={handleInputChange} className="form-control form_left" placeholder="First Name*" required />
                             </div>
                          </div>
                          :
                             ""}
                          {customField['last_name'] === '1' ?
                          <div className="col-lg-6 col-sm-6 col-12 p-10">
                             <div className="form-group">
                               <input type="text" name="last_name" id="last_name" value={formData.last_name} onChange={handleInputChange} className="form-control form_right" placeholder="Last Name*" />
                             </div>
                          </div>
                          :""}
                          {customField['email'] === '1' ?
                          <div className="col-lg-12 p-10">
                             <div className="form-group">
                               <input type="email" value={formData.email} onChange={handleInputChange} name="email" id="email_address" className="form-control" placeholder="Email Address*" required />
                             </div>
                          </div>
                          :
                          ""}
                          {customField['phone'] === '1' ?
                          <div className="col-lg-12 p-10">
                              <div className="form-group d-flex justify-content-between">
                                <PhoneInput
                                  country={country_id} // Set the default country
                                  value={phoneNumber}
                                  onChange={handlePhoneNumberChange}
                                />
                            </div>
                          </div>
                          :""}
                          {customField['guest_note'] === '1' ?
                          <>
                          <div className="col-lg-12 p-10">
                                <div className="input-group mt-1">
                                <FaRegEdit className=' cursorPointer' style={{marginLeft:"3px",color: "#767676"}}  onClick={showReservationNotes} /><label className='cursorPointer' onClick={showReservationNotes} style={{marginLeft:"5px"}}> Reservation  notes </label>
                                </div>
                                {/* color: #767676; */}
                            </div>
                          {isReservation_notesVisble &&
                            <div className="col-lg-12 p-10 mt-2">
                                <div className="input-group">
                                  <textarea id="reservation_notes" className='form-control' placeholder='Reservation Notes' name='reservation_notes' value={formData.reservation_notes} onChange={handleInputChange} rows={5} style={{width:"100%"}}></textarea>
                                </div>
                            </div>
                          }
                          </>
                          :""}
                          {customField['terms_condition'] === '1' ?
                          <div className='mt-1 d-flex align-items-center'>
                              <input required className="float-left" type="checkbox" checked={formData.terms_condition}  onChange={handleInputChange} id="terms_condition" name="terms_condition" />
                              <label className="lable_text float-left" htmlFor="terms_condition">I accept the general terms and conditions <a href='https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf' target="_blank" style={{color:backgroundColorDynamic}}><MdOutlineOpenInNew /></a> </label>
                          </div>
                          :
                          ""
                        }
                        {customField['newsletter'] === '1' ?
                          <div className='mt-1 d-flex align-items-center'>
                              <input className="float-left" type="checkbox" checked={formData.news_offer_venue} onChange={handleInputChange} id="news_offer_venue" name="news_offer_venue" />
                              <label className="lable_text float-left" htmlFor="news_offer_venue">I wish to receive updates and news from the organisation</label>
                          </div>
                          :
                          ""
                        }
                        {customField['room_number'] === '1' ?
                          <div className="col-lg-12 p-10">
                            <div className="form-group">
                              <input type="text" value={formData.room_number} onChange={handleInputChange} name="room_number" id="room_number" className="form-control" placeholder="Room Number" required />
                            </div>
                          </div>
                          :
                          ""
                        }
                          </div>
                          <div className='mt-3 text-center'>
                              <input type='submit' className='submit-btn text-uppercase' style={{backgroundColor:backgroundColorDynamic,borderColor:backgroundColorDynamic}} value="Request To Book" />
                          </div>
                      </div>
                    :
                    ""}
              </div>
                </form>

                }
                
                </div>
              </div>
            </div>
            </div>
            }
        {bookingIdGet &&
          <div>
              <div className='cancel-div row'>
                {/* this should be came after the cancel*/}
                    <div className='text-white row' style={{backgroundColor:backgroundColorDynamic}}>
                    <div className='col-md-2 col-2 p-0'></div>
                    <div className='col-md-8'>
                      <p className='text-center' style={{fontSize:"13px",fontWeight:"500"}}>{descText}</p>
                    </div>
                    <div className='col-md-2 col-2 p-0'></div>
                    </div>
                  <div className=''> 
                    <div className='mt-2 ml-2 border rounded col-lg-10 col-md-10 col-10 mx-auto'>
                    {/* Jul 9,2023(Sun) 16:30  */}
                    <p className='text-center  cancel-text-head mt-1'>{
                        bookingDate.toLocaleString('en-US',{
                          month:'short',
                          day:'numeric',
                          year:'numeric',
                          hour:'2-digit',
                          minute:'2-digit'
                        })
                      }
                    </p>
                    <p className='text-center cancel-text-head'>{companyName}</p>
                    {bookStatus === "1" &&
                      <p className='text-center cancel-text-head'>Reservation ID: {bookingIdGet}</p>
                    }
                    
                  </div> 
                  {bookStatus === '1' &&
                    <>
                      <div className='mt-1 p-3 col-lg-10 col-md-10 col-10 mx-auto '>
                      <form onSubmit={handleCancel}>
                        <p className='cancel-text'> Reason for Cancellation</p>
                        <div className='form-group radiomargin'>
                          <input type='radio' name='reason' id="betterDeal" value="Found a better deal elsewhere"
                            checked={radioValue === "Found a better deal elsewhere"}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="betterDeal" className='radiomargin'>Found a better deal elsewhere</label><br />
                          <input type='radio' name='reason' id="bad_weather" value="bad weather or traffic"
                            checked={radioValue === "bad weather or traffic"}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="bad_weather" className='radiomargin'>Bad weather or traffic</label><br />
                          <input type='radio' name='reason' id="mistake" value="ade a mistake"
                            checked={radioValue === "Made a mistake"}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="mistake" className='radiomargin'>Made a mistake</label><br />
                          <input type='radio' name='reason' id="Illness" value="Illness or personal reasons"
                            checked={radioValue === "Illness or personal reasons"}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="Illness" className='radiomargin'>Illness or personal reasons</label><br />
                          <input type='radio' name='reason' id="different_localtion" value="Wish to book at a different location" 
                            checked={radioValue === "Wish to book at a different location"}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="different_localtion" className='radiomargin'>Wish to book at a different location</label><br />
                          <input type='radio' name='reason' id="plan_change" value="Travel plans changed"
                            checked={radioValue === "Travel plans changed"}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="plan_change" className='radiomargin'>Travel plans changed</label><br />
                          <input type='radio' name='reason' id="other" value="other" onClick={showOtherBbox} 
                            checked={radioValue === "other"}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="other" className='radiomargin' >Other (please specify)</label><br />
                          {otherBoxVisible &&
                            <textarea className='form-control text-area-cancel' style={{marginLeft:"20px", width: "95%",}} name='notes'
                            value={textareaOther}
                            onChange={handleTextareaChange}
                            ></textarea>
                          }
                        </div>
                        <div className='text-center-mob' style={{textAlign:'center'}}>
                          <button type='submit' className='mt-2 rounded text-white cancel-button' 
                          style={{
                              backgroundColor:backgroundColorDynamic,
                              borderColor:backgroundColorDynamic,
                              width:"50%",
                              height:"30px"
                              }}
                              disabled={radioValue === ""}
                              >
                                Cancel Reservation</button>
                        </div>
                      </form>
                      </div>
                    </>
                  }
                  {bookStatus === '0' &&
                    <>
                     
                  <div className='col-lg-10 col-md-10 col-10 p-0 border mt-3 mx-auto'>
                    <p className='text-center cancel-text-head mt-2'>You've successfully cancelled your reservation.</p>
                    <p className='text-center cancel-text-head mt-2'>Reservation ID: {bookingIdGet}</p>
                    <p className='text-center cancel-text-head mt-2'>
                    <button className='mb-2 text-white' 
                      style={{
                        backgroundColor:backgroundColorDynamic,
                        borderColor:backgroundColorDynamic,
                        width:"65%",
                        borderRadius:"5px",
                        boxShadow:"0px 0px 5px 0px rgba(0,0,0,0.5)",
                        }} 
                        onClick={goToSite}
                        >Go To Venue</button>
                    </p>
                    
                  </div>
                    </>
                  }
                  
                  
                  </div>
              </div>
          </div>
        }
      {isConfirmShow &&
        <div className='container-fluid'> 
            <div className='row'>
              <div className="col-lg-12 col-12 col-md-12 margin_text">
                <div>
                  <div className='text-center bg-white'>
                        <FaCheckCircle className='mt-2 mb-2' style={{color:backgroundColorDynamic,height:"50px" ,width:"50px"}}/>
                        <p className='con-book mt-2 mb-2'>Booking successfull your <b>Booking ID is: {bookingId}</b></p>
                        <div className="taking_main_div_text">
                            <div className='row' style={{padding: "0px 32px 15px 26px",justifyContent:"center"}}>
                              <div className='col-sm-12 col-lg-12 selected-bar' style={{width:"60%",fontSize:"16px",borderColor:backgroundColorDynamic}}>
                              <span className="text_guests">
                                {date.toLocaleDateString('en-US', {
                                  weekday: 'short',
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                })}&nbsp;&nbsp;·&nbsp;&nbsp;{guestCapacity} guests&nbsp;&nbsp;·&nbsp;&nbsp;{time}</span>
                                {/* <span className="cursorPointer head-icon" onClick={backToSelection}> <BiCalendarEdit style={{fontSize:"20px",marginTop:"-5px"}}/></span> */}
                              </div>
                            </div>
                      </div>
                      {formData['email'] !== "" &&
                        <p className='con-book mt-2 mb-2'>
                        You will shortly receive the summary of your request by email.<br/>
                          <b>Make sure you check your junk mail.</b>
                        </p>
                      }
                        <button className='mt-2 mb-2 confirm-Book' style={{backgroundColor:backgroundColorDynamic,borderColor:backgroundColorDynamic}} onClick={gotoHome}><FaHome className='mb-1'/>  &nbsp;Go To Home</button>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-12 col-md-3 p-0">
              </div> */}
            </div>
        </div>
      }
      {isFailedShow &&
      <>
        {/* Failed Page Goes Here */}
        <div className='container-fluid'> 
            <div className='row'>
              <div className="col-lg-12 col-12 col-md-12 margin_text">
                <div>
                  <div className='text-center bg-white'>
                        <FaTimesCircle className='mt-2 mb-2' style={{color:backgroundColorDynamic,height:"50px" ,width:"50px"}}/>
                        <p className='con-book mt-2 mb-2'>
                        Something went wrong....<br/>
                          <b>Booking not available please try after some time.</b>
                        </p>
                        {/* backgroundColorDynamic */}
                        <button className='mt-2 mb-2 confirm-Book' onClick={gotoHome} 
                        style={{backgroundColor:backgroundColorDynamic,borderColor:backgroundColorDynamic}}>
                          <FaHome className='mb-1'/>  &nbsp;Go To Home
                        </button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </>
      }
      
		      </div>
          <div className='modal-footer p-0 border-top-0'>
          <div className='col-12 text-center' style={{height:"30px",width:"98%",backgroundColor:"#fff" ,color:"#000",lineHeight:"0"}}>
                <span className='foot-content'>Powered by <span style={{color:backgroundColorDynamic,fontWeight:"600"}}>Book Tech Labs</span></span>
            </div>
          </div>
		    </div>
		  </div>
		</div>
    {isProcessLoader &&
        <div id="custom__ripple_Loader" className="box">
          <div className="ripple__rounds" style={{backgroundColor:backgroundColorDynamic}}></div>
        </div>
      }
      {/* modal for addon data to database */}
      <div className="modal fade" id="infoModal" tabIndex="-1" role="dialog" style={{backgroundColor:"rgb(204 204 204 / 82%)"}}>
          <div className="modal-dialog" role="document">
            <div className="modal-content" >
              <div className="modal-header text-center">
                <h5 className='' style={{marginLeft:"43%",fontWeight:"500"}}>Addons</h5>
              </div>
              <div className="modal-body-addon p-3">
                {addonDataArray.length !== 0 ?
                <div className='row'>
                    {addonDataArray.map((value,index) =>(
                        <div className='row mt-2' key={index}>
                            <div className='col-lg-4 col-4'>
                                <span className='summery-text'>{value['name']}</span>
                            </div>
                            <div className='col-lg-4 col-4'>
                                <span className='summery-text'>$ {value['rate']}</span>
                            </div>
                            <div className='col-lg-4 col-4'>
                            <span className='summery-text cursorPointer' onClick={() =>deleteAddon(value['id'],value['name'],value['rate'])} style={{color:"red"}}><FaTimesCircle /> </span>
                            </div>
                          
                        </div>    
                  ))}
                </div>
                  :
                ""
                }
              </div>
              <div className="text-center mb-3">
                <button type="button" className="btn btn-secondary" onClick={hideModal}> Close </button>
              </div>
            </div>
          </div>
        </div>
        {/* modal for addons show */}
        <div className="modal fade" id="addonArrayModal" tabIndex="-1" role="dialog" style={{backgroundColor:"rgb(204 204 204 / 82%)"}}>
          <div className="modal-dialog modal-content-new" role="document">
            <div className="modal-content animated slideInUp" >
              <div className="modal-header text-center">
                <h5 className='addonArrayModal'>Categories </h5>
              </div>
              <div className="modal-body-addon p-3">
                  <div className='col-12'>
                                      <ul className="list-category">
                                        {addonCategory.length !== 0 ? 
                                            addonCategory.map((value,key)=>(
                                              <li key={key} className={activeItem === value['id'] ? 'active-category' : ''}
                                                onClick={() => handleItemClick(value['id'],key)}
                                              >{value['name']}</li>
                                            ))
                                          :
                                          ""
                                        }
                                      </ul>
                    </div>
              </div>
              <div className="text-center mb-3">
                <button type="button" className="btn btn-secondary" onClick={hideAddonModal}> Close </button>
              </div>
            </div>
          </div>
        </div>
    </main>
   //</SectionContainer>       
    
  );
}
export default App;

